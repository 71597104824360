import React from "react";
import { useFetch } from "../../services/classes";
import { navigate } from 'gatsby';
import ClassTile from "../classes/ClassTile";
import PostCard from "./PostCard";

export default function PostsGrid() {
	
	const { data: posts, loading, error} = useFetch("posts?_sort=created_at:DESC");

	return (
		<div className="">
			{posts &&
				posts.map((item, index) => (
					<PostCard
						key={index}
						cover={item.cover?.url || false}
						title={item.title}
						summary={item.summary}
						author={item.instructor?.name}
						id={item.id}
						nameFont={item.nameFont}
						onClick={() => navigate(`/blog/${item.id}`)}
						// bottomTitle={bottomTitle}
						// playButton={playButton}
						noMargin={true}
					/>
				))}
				
		</div>
	);
}
