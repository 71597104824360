import { navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ClassBanner from "../../components/classes/ClassBanner";
import ClassesSlider from "../../components/classes/ClassesSlider";
import ClassTabs from "../../components/classes/ClassTabs";
import Categories from "../../components/explore/Categories";
import ClassesGrid from "../../components/explore/ClassesGrid";
import Container from "../../components/layout/Container";
import Layout from "../../components/shared/layout";
import SectionTitle from "../../components/ui/SectionHeader";
import VideoPlayer from "../../components/VideoPlayer";
import { useAuth } from "../../context/AuthContext";
import { getClass, useFetch } from "../../services/classes";
import PostsGrid from "../../components/blog/PostsGrid";

const IndexPage = ({ params, category }) => {
	// const category = params["category"];
	const { userData, authModal } = useAuth();

	// const { data: classData, loading: loadingClass, error: classError} = useFetch(`classes?category=${data}`);

	return (
		<Layout transparentNavbar={false} seoTitle="Blog">
			<Container>
				<SectionTitle
					title="K-Blog"
					subtitle="Bringing together some of the sharpest minds, creative artists, and influencers in Korean culture, music."
				/>
				<div className="pb-20 max-w-3xl mx-auto">
					<PostsGrid />
				</div>
			</Container>
		</Layout>
	);
};

export default IndexPage;
