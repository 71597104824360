import "../../styles/class-tile.css";
import React from "react";
import { Colors } from "../../constants/colors";

export default function PostCard({
	children,
	style,
	id,
	title,
	summary,
	author,
	objective,
	cover,
	openClass,
	bottomTitle,
	playButton,
	onClick,
	noMargin,
	...others
}) {
	const styles = {
		classTile: {
			flexDirection: "column",
			display: "flex",
			//height: 382,
			position: "relative",
			backgroundColor: Colors.grey20,
			backgroundImage: `url(${cover})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			borderRadius: 12,
			// overflow: 'hidden',
			marginRight: 20,
			cursor: "pointer",
		},
		bottomGradient: {
			height: "100%",
		},
		blurView: {
			position: "absolute",
			bottom: 0,
			left: 0,
			right: 0,
			height: "22%",
			justifyContent: "center",
		},
		classInfo: {
			padding: 15,
		},
		title: {
			opacity: 1,
			//fontSize: '2.2rem',
			fontWeight: 800,
			// textTransform: 'uppercase',
			lineHeight: 1.1,
			// display: 'flex',
			// justifyContent: 'center',
			// flexDirection: 'column',
			// minHeight: 74,
			padding: "0px 5px",
		},
		subtitle: {
			marginTop: 20,
			minHeight: 48,
			fontSize: 14,
			lineHeight: 1.4,
		},
		bottomTitle: {
			backgroundColor: "#222",
			//height: 30,
			position: "absolute",
			bottom: 0,
			left: 0,
			right: 0,
			padding: 10,
		},
		playContainer: {
			position: "absolute",
			zIndex: 9,
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		playButton: {
			backgroundColor: "rgba(0,0,0,0.5)",
			borderRadius: 100,
			width: 40,
			height: 40,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	};

	return (
		<div className="flex !items-center cursor-pointer my-8" onClick={onClick}>
			<div
				style={styles.classTile}
				className="class-tile w-[300px] max-w-full h-[200px] mb-6 md:mb-0"
				
			></div>
			<div style={styles.classInfo} className="flex-1">
				<div
					className="font-hn-ex font-bold text-[20px] md:text-[28px]"
					style={styles.title}
				>
					{title}
				</div>
				<div style={styles.subtitle} className="text-gray-100">
					{summary}
				</div>
			</div>
		</div>
	);
}
